import React from 'react';
import SEO from '../components/seo';
import HeroSection from '../components/HeroSection';
import Section from '../components/Section';
import { Heading1, Heading2, Paragraph } from '../components/Typography';
import ServicesSection from '../components/ServicesSection';
import styled from 'styled-components';

const ServicesPage = () => {
  return (
    <>
      <SEO
        title='Self-Service'
        description={`
                Jacobs are the leading Debt Recovery and Enforcement Services provider in the United Kingdom, with 60 years experience working with local authorities across the country.
                `}
      />
      <HeroSection paddingBottom='70px' paddingTop='8px'>
        <Heading1 marginTop='120px'>Self-Service</Heading1>
        <Paragraph maxWidth='680px'>
          Jacobs provides a range of services that aim to assist you in managing
          your account. Check an outstanding balance, make payments, provide
          proof of residency and more, from this page.
        </Paragraph>
      </HeroSection>
      <Section
        backgroundColor='grey'
        paddingTop='80px'
        paddingBottom='120px'
        justifyContent='center'
      >
        <ServicesSection />
      </Section>
    </>
  );
};

export default ServicesPage;

const Container = styled.div`
  display: flex;
  justify-content: center;
`;
