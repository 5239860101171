import React from 'react';
import styled from 'styled-components';
import Section from '../Section';
import { Paragraph } from '../Typography';
import Link from '../Link';
import Card from '../Card';
import webservices from '../../constants/webservices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCreditCard,
	faFileAlt,
	faCoins,
	faEnvelopeOpenText,
} from '@fortawesome/free-solid-svg-icons';

const servicesData = [
	{
		key: 'payment',
		title: 'Make a payment',
		content: [
			'Use our secure payment facility to make an online payment, or visit our ',
			// eslint-disable-next-line react/jsx-key
			<Link href='/ways-to-pay'>ways to pay</Link>,
			' page for more payment options.',
		],
		link: '/pay-now',
		img: faCreditCard,
	},
	{
		key: 'received-letter',
		title: "Received a letter that's not for you?",
		content:
			'Provide proof of your residency to stop any further action from being taken.',
		link: '/submit-proof',
		img: faFileAlt,
	},
	{
		key: 'contact-form',
		title: 'Contact Form',
		content: [
			'Access our contact form by entering your Jacobs reference, Client reference and Postcode. Alternatively, you can visit our ',
			// eslint-disable-next-line react/jsx-key
			<Link href='/contact'>contact page</Link>,
			' for more options.',
		],
		link: webservices.contactPage.url,
		img: faEnvelopeOpenText,
	},
	{
		key: 'check-balance',
		title: 'Check balance',
		content:
			'Enter your Jacobs reference , Client reference, and postcode to view your current outstanding debt.',
		link: webservices.checkBalance.url,
		img: faCoins,
	},
];

const ServicesCard = ({ title, img, content, link, ...rest }) => {
	return (
		<Card margin='8px' padding='32px' flex='1 0 48%' {...rest}>
			<Contents>
				<FontAwesomeIcon
					icon={img}
					color='#27315f'
					size='md'
					style={{ marginTop: '4px', marginRight: '8px' }}
				/>
				<div>
					<Link
						href={link}
						fontSize='20px'
						style={{ width: '200px' }}
					>
						{title}
					</Link>
					<Paragraph marginBottom='0px'>{content}</Paragraph>
					<div style={{ textAlign: 'right' }}>
						<Link href={link}>&rarr;</Link>
					</div>
				</div>
			</Contents>
		</Card>
	);
};

const ServicesSections = () => {
	return (
		<Grid>
			{servicesData.map((item) => (
				<ServicesCard
					key={item.key}
					title={item.title}
					content={item.content}
					link={item.link}
					img={item.img}
				/>
			))}
		</Grid>
	);
};

export default ServicesSections;

const Grid = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin: 16px;
	padding-bottom: 80px;
`;

const Contents = styled.div`
	display: flex;
`;
