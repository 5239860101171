export default {
  contactPage: {
    title: 'Contact Form',
    description:
      'Access our contact form by entering your Jacobs reference, Client reference and Postcode. Alternatively, you can visit our contact page for more options.',
    url: '/contact-form',
  },
  checkBalance: {
    title: 'Check your Balance',
    description:
      'Check your current outstanding balance by entering your Jacobs reference, Client reference, and postcode.',
    url: '/web-services/view-balance',
  },
  payNow: {
    title: 'Pay Now',
    description:
      'To access the payment system please enter your Jacobs reference and your council reference.',
    url: '/web-services/pay-now',
  },
};
